import React, { useState } from "react";
import { styled } from "@material-ui/core/styles";
import {
  Menu,
  MenuItem,
  AppBar,
  Button,
  Divider,
  Grid,
  Box,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import HamburgerSpring from "react-animated-burgers/lib/components/HamburgerSpring/HamburgerSpring";
import { useParams, useHistory } from "react-router-dom";
import Lil from "@loginline/core";
import useFillStore from "../hooks/useFillStore";
import { enrichDictWithList } from "../utils";
import { useClients } from "../hooks";

export default function Navbar() {
  let { clientId } = useParams();
  const [store, update] = Lil.useStore();

  const [{ logo }, loadingLogo, errorLogo] = useFillStore(
    "pub/logoUrl",
    {},
    {},
    (store, result) => ({
      logo: result.url,
    })
  );

  const [clients, loadingClients, errorClients] = useClients();

  const [{ nbNotifs }, loadingNotifs, errorNotifs] = useFillStore(
    "auth/nbNotifs",
    { id: clientId },
    {
      skip: clientId === undefined,
    },
    (store, result) => ({
      nbNotifs: result.length,
    })
  );

  const classes = useStyles();
  const isMobile = window.innerWidth < 760;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElBis, setAnchorElBis] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setMenuIsActive(!isMenuActive);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setMenuIsActive(!isMenuActive);
  };

  const handleClickBis = event => {
    setAnchorElBis(event.currentTarget);
  };
  const handleCloseBis = () => {
    setAnchorElBis(null);
  };

  const router = useHistory();
  const [isMenuActive, setMenuIsActive] = useState(false);
  // eslint-disable-next-line

  return (
    <Box className={classes.NavBar}>
      <Grid container spacing={0}>
        <Grid item xs={1} md={10}>
          <AppBar
            className={classes.AppBar}
            aria-controls='simple-menu'
            aria-haspopup='true'
            onClick={handleClick}
          >
            <Typography variant='h3' className={classes.clientName}>
              {clients && clients[clientId] && clients[clientId].name}
            </Typography>
            <HamburgerSpring
              className={classes.hamburger}
              style={{ position: "absolute", right: "15px", top: "13px" }}
              isActive={isMenuActive}
              barColor='white'
            />
          </AppBar>
        </Grid>
        {!isMobile && (
          <Grid item xs={11} md={2}>
            {logo && <img src={logo} alt='logo' />}
          </Grid>
        )}
      </Grid>
      <Typography>
        <Menu
          className={classes.menu}
          PaperProps={{ className: classes.submenu }}
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MyMenuItem
            aria-controls='simple-menu'
            aria-haspopup='true'
            onClick={handleClickBis}
          >
            Clients
          </MyMenuItem>
          <Menu
            id='simple-menu'
            anchorEl={anchorElBis}
            keepMounted
            open={Boolean(anchorElBis)}
            onClose={handleCloseBis}
            PaperProps={{ className: classes.submenu }}
          >
            {clients &&
              Object.values(clients).map(client => {
                return (
                  <MyLink to={`/client/${client.id}`} key={client.id}>
                    <MyMenuItem>{client.name}</MyMenuItem>
                  </MyLink>
                );
              })}
          </Menu>
          <Divider light />
          <MyLink to={`/client/${clientId}`}>
            <MyMenuItem onClick={handleClose}>Accueil</MyMenuItem>
          </MyLink>
          <Divider light />
          <MyLink to={`/client/${clientId}/notifications`}>
            <MyMenuItem onClick={handleClose}>
              Notifications{" "}
              {nbNotifs ? (
                <Box className={classes.notifications}>{nbNotifs}</Box>
              ) : (
                ""
              )}
            </MyMenuItem>
          </MyLink>
          <Divider light />
          <MyLink to={`/client/${clientId}/events`}>
            <MyMenuItem onClick={handleClose}>Évenements</MyMenuItem>
          </MyLink>
          <Divider light />
          <MyLink to={`/client/${clientId}/synthesis`}>
            <MyMenuItem onClick={handleClose}>
              Évolution par classe <br /> d'actifs
            </MyMenuItem>
          </MyLink>
          <MyLink to={`/app/myaccount`}>
            <MyMenuItem onClick={handleClose}>
              <Button className={classes.whiteBtn}>Mon compte</Button>
            </MyMenuItem>
          </MyLink>
          <MyMenuItem onClick={handleClose}>
            <Button
              onClick={() => {
                Lil.Account.logout();
                Lil.resetStore(update);
                router.push("/");
              }}
            >
              Se déconnecter
            </Button>
          </MyMenuItem>
        </Menu>
      </Typography>
    </Box>
  );
}

const MyMenuItem = styled(MenuItem)({
  fontWeight: "bold",
  color: "white",
  padding: "13px 0",
});

const MyLink = styled(Link)({
  textDecoration: "none",
});

const useStyles = makeStyles(theme => ({
  AppBar: {
    background: theme.palette.primary.main,
    borderRadius: "0px 0px 20px 0px",
    boxShadow: "none",
    color: theme.palette.white.main,
    height: 83,
    width: 307,
    padding: "0 30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  clientName: {
    maxWidth: 180,
    marginLeft: -90,
  },
  hamburger: {
    marginLeft: 100,
  },
  NavBar: {
    backgroundColor: theme.palette.light.main,
  },
  whiteBtn: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.primary.main,
  },
  menu: {
    background: theme.palette.primary.main,
    width: "307px",
    height: "91vh",
    marginTop: 65,
    borderRadius: "0px 0px 20px 0px",
  },
  submenu: {
    backgroundColor: theme.palette.primary.main,
  },
  link: {
    textDecoration: "none",
  },
  notifications: {
    marginLeft: 20,
    backgroundColor: "red",
    color: "white",
    textAlign: "center",
    borderRadius: 20,
    fontSize: 15,
    width: 22,
    height: 22,
    paddingRight: 2,
    paddingLeft: 2,
  },
}));
