import { useState } from "react";
import useFillStore from "./useFillStore";
import { enrichDictWithList } from "../utils";
import Lil from "@loginline/core";

export const useAssetClasses = () => {
  const [{ assetClasses }, loadingAssetClasses, errorAssetClasses] =
    useFillStore(
      "auth/readAssetClasses",
      {
        fields: ["id", "name"],
      },
      {
        fetchPolicy: "cache-first",
        cacheKey: "assetClasses",
      },
      (store, result) => ({
        assetClasses: result.reduce((acc, cv) => ({ ...acc, [cv.id]: cv })),
      }),
      () => {
        console.error("Erreur lors de la récupération des classes d'actifs");
      }
    );

  return [assetClasses, loadingAssetClasses, errorAssetClasses];
};

export const useDocuments = (clientId, pageSize) => {
  const [page, setPage] = useState(1);
  const [documentType, setDocumentType] = useState("all");
  const [nDocs, setNDocs] = useState(0);

  const [{ documents }, loadingDocs, errorDocs] = useFillStore(
    "auth/readDocuments",
    {
      client_id: clientId,
      fields: ["id", "name", "create_date"],
      offset: (page - 1) * pageSize,
      limit: pageSize,
      order: "create_date desc",
      documentType,
    },
    {},
    (store, result) => {
      setNDocs(result.totalCount);
      return {
        documents: enrichDictWithList(store.documents, result.docs),
      };
    }
  );

  return [
    documents,
    loadingDocs,
    errorDocs,
    page,
    setPage,
    documentType,
    setDocumentType,
    nDocs,
    setNDocs,
  ];
};

export const useClients = () => {
  const [{ clients }, loadingClients, errorClients] = useFillStore(
    "auth/readClients",
    {
      fields: ["id", "name", "date_analyse_encours_customer"],
    },
    {
      fetchPolicy: "cache-first",
      cacheKey: "clients",
    },
    (store, result) => ({
      clients: enrichDictWithList(store.clients, result),
    }),
    () => {
      console.error("Erreur lors de la récupération des clients");
    }
  );

  return [clients, loadingClients, errorClients];
};

export const useHistoryLines = clientId => {
  const [{ historyLines }, loadingHistory, errorHistory] = useFillStore(
    "auth/readHistoryLines",
    {
      id: clientId,
      fields: [
        "id",
        "name",
        "montant_investi",
        "montant_valorisation",
        "plus_value_souscription",
      ],
    },
    {},
    (store, result) => ({
      historyLines: enrichDictWithList(store.historyLines, result),
    })
  );

  return [historyLines, loadingHistory, errorHistory];
};

export const useComments = (clientId, assetClassId) => {
  const [{ comments }, loadingComments, errorComments] = useFillStore(
    "auth/readComments",
    { fields: ["id", "text", "instrument_id"] },
    {
      fetchPolicy: "cache-first",
      cacheKey: "comments",
    },
    (store, result) => ({
      comments: enrichDictWithList({}, result),
    }),
    () => {
      console.error("Erreur lors de la récupération des commentaires");
    }
  );

  return [comments, loadingComments, errorComments];
};

export const useClientStocks = (clientId, assetClassId) => {
  const [{ stocks }, loadingStocks, errorStocks] = useFillStore(
    "auth/readStocks",
    {
      id: clientId,
      assetClassId: assetClassId,
      fields: [
        "id",
        "true_classe_actif",
        "montant_reel_encours_customer",
        "montant_valorisation_encours_customer",
        "montant_garanti_encours_customer",
        "plus_value_souscription_encours_customer",
        "coupon_percu_souscription_encours_customer",
        "resultat_net_souscription_encours_customer",
        "indexation_ids",
        "instrument_id",
        "date_debut",
        "date_fin",
        "rendement_coupon_couru_encours_customer",
        "rendement_net_souscription_encours_customer",
        "categorie_produit",
        "rendement_estime",
        "nombre",
        "derniere_valorisation_encours_customer",
        "date_derniere_valorisation",
        "montant_garanti_encours_customer",
        "commentaire",
      ],
    },
    {},
    (store, result) => ({
      stocks: {
        ...(store.stocks || {}),
        [clientId]: {
          ...((store.stocks && store.stocks[clientId]) || {}),
          [assetClassId]: {
            ...((store.stocks &&
              store.stocks[clientId] &&
              store.stocks[clientId][assetClassId]) ||
              {}),
            ...result.reduce(
              (acc, cv) => ({
                ...acc,
                [cv.id]: cv,
              }),
              {}
            ),
          },
        },
      },
    }),
    () => {
      console.error("Erreur lors de la récupération des stocks");
    }
  );

  return [stocks, loadingStocks, errorStocks];
};

export const useIndexations = ids => {
  const [{ indexations }, loadingIndexations, errorIndexations] = useFillStore(
    "auth/readIndexation",
    {
      indexations_ids: ids,
      fields: [
        "id",
        "name",
        "index_id",
        "date",
        "valeur_cloture",
        "barriere_ids",
      ],
    },
    {
      skip: !ids,
    },
    (store, result) => ({
      indexations: enrichDictWithList(store.indexations, result),
    }),
    () => {
      console.error("Erreur lors de la récupération des indexations");
    }
  );

  return [
    indexations &&
      Object.values(indexations).filter(indexation =>
        ids.includes(indexation.id)
      ),
    loadingIndexations,
    errorIndexations,
  ];
};

export const useInstruments = ids => {
  const [{ instruments }, loadingInstruments, errorInstruments] = useFillStore(
    "auth/ReadInstrumentsStocks",
    {
      instrument_ids: ids,
      fields: [],
    },
    {
      skip: !ids,
    },
    (store, result) => ({
      instruments: enrichDictWithList(store.instruments, result),
    }),
    () => {
      console.error("Erreur lors de la récupération des instruments");
    }
  );

  return [instruments, loadingInstruments, errorInstruments];
};

export const useValorisations = instrumentId => {
  const [{ valorisations }, loadingValorisations, errorValorisations] =
    useFillStore(
      "auth/readValo",
      {
        instrument_id: instrumentId,
        fields: ["id", "date", "valeur", "instrument_id"],
      },
      {
        skip: !instrumentId,
      },
      (store, result) => ({
        valorisations: enrichDictWithList(store.valorisations, result),
      }),
      () => {
        console.error("Erreur lors de la récupération des valorisations");
      }
    );

  return [
    valorisations &&
      Object.values(valorisations).filter(
        valorisation => valorisation.instrument_id[0] === instrumentId
      ),
    loadingValorisations,
    errorValorisations,
  ];
};

export const useValeurs = indexIds => {
  const [{ valeurs }, loadingValeurs, errorValeurs] = useFillStore(
    "auth/readValeurs",
    {
      index_ids: indexIds,
      fields: ["index_id", "date", "valeur_cloture", "year", "index_id"],
    },
    {
      skip: !indexIds,
    },
    (store, result) => ({
      valeurs: enrichDictWithList(store.valeurs, result),
    }),
    () => {
      console.error("Erreur lors de la récupération des valeurs");
    }
  );

  return [
    valeurs &&
      Object.values(valeurs).filter(valeur =>
        indexIds.includes(valeur.index_id[0])
      ),
    loadingValeurs,
    errorValeurs,
  ];
};

export const usePeriodes = instrumentId => {
  const [{ periodes }, loadingPeriodes, errorPeriodes] = useFillStore(
    "auth/readPeriodes",
    {
      instrument_id: instrumentId,
      fields: [
        "commentaire",
        "date_constatation",
        "coupon",
        "end_date",
        "instrument_id",
      ],
    },
    {
      skip: !instrumentId,
    },
    (store, result) => ({
      periodes: enrichDictWithList(store.periodes, result),
    }),
    () => {
      console.error("Erreur lors de la récupération des périodes");
    }
  );

  return [
    periodes &&
      Object.values(periodes).filter(
        periode => periode.instrument_id[0] === instrumentId
      ),
    loadingPeriodes,
    errorPeriodes,
  ];
};

export const useBarrieres = ids => {
  const [{ barrieres }, loadingBarrieres, errorBarrieres] = useFillStore(
    "auth/readBarrieres",
    {
      barriere_ids: ids,
      fields: [
        "indexation_id",
        "date_constatation_initiale",
        "valeur_evaluation",
        "date_derniere_constatation",
        "valeur_derniere_evaluation",
        "start_date",
        "end_date",
        "barriere_remboursement",
        "valeur_barriere_remboursement",
        "barriere_paiement",
        "valeur_barriere_paiement",
        "barriere_protection",
        "valeur_barriere_protection",
        "show_barriere",
      ],
    },
    {
      skip: !ids,
    },
    (store, result) => ({
      barrieres: enrichDictWithList(store.barrieres, result),
    }),
    () => {
      console.error("Erreur lors de la récupération des barrières");
    }
  );

  return [
    barrieres &&
      Object.values(barrieres).filter(barriere => ids.includes(barriere.id)),
    loadingBarrieres,
    errorBarrieres,
  ];
};

export const useNotifications = clientId => {
  const [{ notifications }, loadingNotifications, errorNotifications] =
    useFillStore(
      "auth/readNotif",
      { id: clientId },
      {
        skip: clientId === undefined,
        fetchPolicy: "cache-first",
        cacheKey: "notifications",
      },
      (store, result) => ({
        notifications: result,
      }),
      () => {
        console.error("Erreur lors de la récupération des notifications");
      }
    );

  return [notifications, loadingNotifications, errorNotifications];
};

export const useDeleteNotification = () => {
  const [store, update] = Lil.useStore();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const removeNotif = async notificationId => {
    setLoading(true);
    try {
      await Lil.method("auth/deleteNotif", { id: notificationId }, () => {
        update({
          notifications: store.notifications.filter(
            notif => notif.id !== notificationId
          ),
        });
        setLoading(false);
      });
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return [removeNotif, loading, error];
};

export const useEvents = (clientId, pageSize, type) => {
  const [offset, setOffset] = useState(0);

  const [{ pastEvents, futureEvents, events }, loading, error] = useFillStore(
    "auth/readEvents",
    {
      id: clientId,
      fields: [
        "id",
        "nom_evenement",
        "date_evenement",
        "commentaire",
        "instrument_id",
      ],
      limit: pageSize,
      offset,
      order: type === "future" ? "date_evenement asc" : "date_evenement desc",
      filter:
        type === "future"
          ? [["date_evenement", ">", new Date().toISOString().slice(0, 10)]]
          : type === "past"
          ? [["date_evenement", "<=", new Date().toISOString().slice(0, 10)]]
          : [],
    },
    {
      fetchPolicy: "cache-first",
      cacheKey: "events",
    },
    (store, result) => {
      if (type === "future") {
        return {
          futureEvents: {
            ...(store.futureEvents || {}),
            [clientId]: [
              ...((store.futureEvents && store.futureEvents[clientId]) || []),
              ...result,
            ],
          },
        };
      } else if (type === "past") {
        return {
          pastEvents: {
            ...(store.pastEvents || {}),
            [clientId]: [
              ...((store.pastEvents && store.pastEvents[clientId]) || []),
              ...result,
            ],
          },
        };
      } else {
        return {
          events: {
            ...(store.events || {}),
            [clientId]: [
              ...((store.events && store.events[clientId]) || []),
              ...result,
            ],
          },
        };
      }
    },
    () => {
      console.error("Erreur lors de la récupération des événements");
    }
  );

  const fetchMore = () => {
    setOffset(offset + pageSize);
  };

  return [
    type === "future"
      ? futureEvents &&
        futureEvents[clientId] &&
        futureEvents[clientId].slice(0, offset + pageSize)
      : type === "past"
      ? pastEvents &&
        pastEvents[clientId] &&
        pastEvents[clientId].slice(0, offset + pageSize)
      : events &&
        events[clientId] &&
        events[clientId].slice(0, offset + pageSize),
    loading,
    error,
    fetchMore,
  ];
};

export const useHistory = clientId => {
  const [{ history }, loadingHistory, errorHistory] = useFillStore(
    "auth/readHistory",
    {
      client_id: clientId,
      fields: [
        "id",
        "date",
        "montant_investi",
        "montant_valorisation",
        "plus_value_souscription",
      ],
    },
    {},
    (store, result) => ({
      history: {
        ...(store.history || {}),
        [clientId]: result,
      },
    }),
    () => {
      console.error("Erreur lors de la récupération de l'historique");
    }
  );

  return [history && history[clientId], loadingHistory, errorHistory];
};

export const useInterlocutor = () => {
  const [{ interlocutor }, loadingInterlocutor, errorInterlocutor] =
    useFillStore(
      "auth/readInterlocutor",
      {
        fields: ["id", "name"],
      },
      {
        fetchPolicy: "cache-first",
        cacheKey: "interlocutor",
      },
      (store, result) => ({
        interlocutor: result,
      }),
      () => {
        console.error("Erreur lors de la récupération de l'interlocuteur");
      }
    );

  return [interlocutor, loadingInterlocutor, errorInterlocutor];
};
