import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Typography,
  Button,
  CircularProgress,
  TextareaAutosize,
} from "@material-ui/core";
import { useParams, Redirect } from "react-router-dom";
import Lil from "@loginline/core";
import ActionFirstChart from "../Components/ActionFirstChart";
import ActionSecondChart from "../Components/ActionSecondChart";
import TableA7StocksDetails from "../Components/TableA7StocksDetails";
import ReactResizeDetector from "react-resize-detector";
import Header from "../Components/Header";
import makeTransparent from "../utils/makeTransparent";
import {
  useBarrieres,
  useClients,
  useComments,
  useIndexations,
  useInstruments,
  usePeriodes,
  useValeurs,
  useValorisations,
} from "../hooks";
import { compareEndDate } from "../utils";

const options = { year: "numeric", month: "numeric", day: "numeric" };
const titles1 = [
  "Constatation initiale",
  "Dernière constatation",
  "Barrière de remboursement",
  "Barrière coupon",
  "Barrière protection",
];
const titles2 = [
  "Index",
  "Date constatation initiale",
  "Valeur constatation initiale",
  "Dernière date de constatation",
  "Dernière valeur de constatation",
  "Date de début",
  "Date de fin",
  "Barrière de remboursement",
  "Valeur barrière de remboursement",
  "Barrière de paiement du coupon",
  "Valeur barrière de paiement du coupon",
  "Barrière de protection du capital",
  "Valeur barrière de protection du capital",
];

const findBarriereValues = (date, barrieres) => {
  var dt = new Date(date);
  const real_date = new Date(dt);
  for (let i = 0; i < barrieres.length; ++i) {
    if (
      real_date >= new Date(barrieres[i].start_date) &&
      real_date <= new Date(barrieres[i].end_date)
    ) {
      return {
        barriere_protection: barrieres[i].valeur_barriere_protection,
        barriere_remboursement: barrieres[i].valeur_barriere_remboursement,
      };
    }
  }
  return undefined;
};

const groupBy = items => {
  const agg = {};
  items.forEach(elem => {
    const cloneElem = { ...elem };
    delete cloneElem.year;
    agg[elem.year] = { ...agg[elem.year], ...cloneElem, year: elem.year };
  });
  return Object.values(agg);
};

export default function StocksDetails() {
  const classes = useStyles();
  const {
    clientId: clientIdString,
    assetClassId: assetClassIdString,
    instrumentId: instrumentIdString,
  } = useParams();
  const clientId = parseInt(clientIdString);
  const assetClassId = parseInt(assetClassIdString);
  const instrumentId = parseInt(instrumentIdString);
  const [width, setWidth] = useState(null);
  const [width2, setWidth2] = useState(null);
  const [yearDisplay, setYearDisplay] = useState(true);
  const [monthDisplay, setMonthDisplay] = useState(false);
  const [weekDisplay, setWeekDisplay] = useState(false);
  const [couponDates, setCouponDates] = useState([]);

  const [clients, loadingClients, errorClients] = useClients();
  const client = clients && clients[clientId];

  const [instruments, loadingInstruments, errorInstruments] = useInstruments([
    instrumentId,
  ]);
  const instrument = instruments && instruments[instrumentId];

  const [valorisations, loadingValorisations, errorValorisations] =
    useValorisations(instrumentId);

  const [indexations, loadingIndexations, errorIndexations] = useIndexations(
    instruments &&
      instruments[instrumentId] &&
      instruments[instrumentId].indexation_ids
  );

  const [valeurs, loadingValeurs, errorValeurs] = useValeurs(
    indexations && indexations.map(indexation => indexation.index_id[0])
  );

  const [periodes, loadingPeriodes, errorPeriodes] = usePeriodes(instrumentId);

  const [barrieres, loadingBarrieres, errorBarrieres] = useBarrieres(
    indexations &&
      indexations.map(indexation => indexation.barriere_ids).reduce((acc, cv) => acc.concat(cv), []),
  );

  const [comments, loadingComments, errorComments] = useComments(
    clientId,
    assetClassId
  );

  const nextPeriode =
    periodes &&
    periodes.find(periode => new Date(periode.date_constatation) >= new Date());

  let periodComment = "Pas de commentaire disponible.";
  let constatationDate = "";

  if (nextPeriode) {
    periodComment = nextPeriode.commentaire;
    constatationDate = nextPeriode.date_constatation;
  }

  const instrumentComments =
    instrument &&
    comments &&
    instrument.comments.map(([id, name]) => comments[id]);

  let actualityComment =
    instrumentComments && instrumentComments.length > 0
      ? instrumentComments[0].text
      : "Pas de commentaire disponible";

  // FIRST CHART

  const lastWeekDate = new Date().getTime() - 1000 * 60 * 60 * 24 * 7;
  const lastMonthDate = new Date().getTime() - 1000 * 60 * 60 * 24 * 30;
  const lastYearDate = new Date().getTime() - 1000 * 60 * 60 * 24 * 365;

  const dataValorisations = valorisations && valorisations.filter(valo =>
    yearDisplay
      ? new Date(valo.date) >= lastYearDate
      : monthDisplay
      ? new Date(valo.date) >= lastMonthDate
      : new Date(valo.date) >= lastWeekDate
  ).map(valo => ({
    dataDate: new Date(valo.date),
    value: valo.valeur.toFixed(2),
  }));

  // SECOND CHART

  const [history, setHistory] = useState(false);
  const [futur, setFutur] = useState(false);
  const indexNames = indexations && indexations.map(indexation => indexation.index_id[1]);
  const arraySortByDate =
    valeurs &&
    valeurs
      .filter(valeur => new Date(valeur.date) <= new Date())
      .map(valeur => {
        return {
          year: valeur.date,
          [valeur.index_id[1]]: valeur.valeur_cloture,
        };
      });

  let dataSecChart = arraySortByDate && groupBy(arraySortByDate);
  let barrieresDispo = [];
  let barrieresRemb = [];

  if (barrieres) {
    const namedBarrieres = barrieres.reduce((acc, cv) => {
      if (cv.show_barriere) {
        if (acc[cv.indexation_id[1]]) {
          return {
            ...acc,
            [cv.indexation_id[1]]: acc[cv.indexation_id[1]].concat(cv),
          };
        } else {
          return {
            ...acc,
            [cv.indexation_id[1]]: [cv],
          };
        }
      } else {
        return { ...acc };
      }
    }, {});

    dataSecChart = dataSecChart && dataSecChart.map(elt => {
      return {
        ...elt,
        ...Object.keys(elt)
          .filter(key => key !== "year")
          .map(key => ({
            key,
            protection: namedBarrieres[key]
              ? findBarriereValues(elt.year, namedBarrieres[key])
                ? findBarriereValues(elt.year, namedBarrieres[key])
                    .barriere_protection
                : undefined
              : undefined,
            remboursement: namedBarrieres[key]
              ? findBarriereValues(elt.year, namedBarrieres[key])
                ? findBarriereValues(elt.year, namedBarrieres[key])
                    .barriere_remboursement
                : undefined
              : undefined,
          }))
          .reduce(
            (acc, cv) => ({
              ...acc,
              ["B" + cv.key]: cv.protection,
              ["R" + cv.key]: cv.remboursement,
            }),
            {}
          ),
      };
    });

    barrieresDispo = Object.keys(namedBarrieres);

    if (futur) {
      Object.entries(namedBarrieres).forEach(([key, value]) => {
        value.forEach(bar => {
          if (bar.show_barriere) {
            if (new Date(bar.start_date) > new Date()) {
              dataSecChart.push({
                year: bar.start_date,
                ["B" + key]: bar.valeur_barriere_protection,
                ["R" + key]: bar.valeur_barriere_remboursement,
              });
            }
            if (new Date(bar.end_date) > new Date()) {
              const day_before = new Date(bar.end_date);
              day_before.setDate(day_before.getDate() - 1);
              dataSecChart.push({
                year: day_before.toISOString().split("T")[0],
                ["B" + key]: bar.valeur_barriere_protection,
                ["R" + key]: bar.valeur_barriere_remboursement,
              });
            }
          }
        });
      });
    }
  }

  // Repère de couleur date reporting dans le tableauFloca
  const reportingMarker = (startDate, endDate) => {
    if (
      Date.parse(startDate) < Date.now() &&
      Date.parse(endDate) > Date.now()
    ) {
      return classes.reportingMarkerStyle;
    }
  };

  let date_const_init = undefined;
  if (barrieres) {
    if (barrieres[0] === undefined) {
      date_const_init = undefined;
    } else {
      date_const_init = barrieres[0].date_constatation_initiale;
    }
  }

  return (
    <>
      <Header title={instruments ? instruments[instrumentId].name : ""} />

      <Typography
        variant='h4'
        className={classes.date}
        style={{ textAlign: "center" }}
      >
        Reporting au {client ? client.date_analyse_encours_customer : ""}
        {/* {formatDate(new Date())} */}
      </Typography>

      <Box>
        {instrument && instrument.categorie_produit[1] === "Action" ? (
          ""
        ) : (
          <Grid className={classes.wrap}>
            {/* <Box className={classes.blockGray} /> */}

            <Grid item xs={12} sm={12} md={9} lg={9}>
              <Box className={classes.whiteContainer}>
                <ReactResizeDetector handleWidth onResize={x => setWidth(x)} />
                {!dataValorisations ? (
                  <CircularProgress />
                ) : (
                  <Grid>
                    <Grid style={{ height: "400px" }}>
                    <ActionFirstChart
                        width={width}
                        data={dataValorisations.reverse()}
                    />
                    </Grid>
                    <Grid className={classes.btnWrapper}>
                    <Button
                        className={classes.whiteBtn}
                        onClick={() => {
                        setYearDisplay(true);
                        setMonthDisplay(false);
                        setWeekDisplay(false);
                        }}
                    >
                        Année
                    </Button>
                    <Button
                        className={classes.whiteBtn}
                        onClick={() => {
                        setMonthDisplay(true);
                        setWeekDisplay(false);
                        setYearDisplay(false);
                        }}
                    >
                        Mois
                    </Button>
                    <Button
                        className={classes.whiteBtn}
                        onClick={() => {
                        setWeekDisplay(true);
                        setYearDisplay(false);
                        setMonthDisplay(false);
                        }}
                    >
                        Semaine
                    </Button>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              className={classes.AreaChartTextGrid}
            >
              <Box style={{ backgroundColor: "#878889", marginTop: 80 }}>
                <Typography
                  variant='h2'
                  style={{ color: "white", margin: 20, padding: 20 }}
                >
                  Évolution de la valorisation depuis la détention
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}

        {!valeurs ? (
          <Box className={`${classes.tableContainer} ${classes.center}`}>
            <CircularProgress />
          </Box>
        ) : valeurs.length > 0 ? (
          <>
            <Grid className={classes.wrap}>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className={classes.AreaChartTextGrid}
              >
                <Box style={{ backgroundColor: "#878889", marginTop: 80 }}>
                  <Typography
                    variant='h2'
                    style={{
                      color: "white",
                      margin: 20,
                      marginBottom: 0,
                      padding: 20,
                    }}
                  >
                    Évolution des index
                  </Typography>
                  <Typography
                    variant='body1'
                    style={{ marginLeft: 20, color: "white" }}
                  >
                    Commentaire période :
                  </Typography>
                  <TextareaAutosize
                    aria-label='minimum height'
                    rowsMin={8}
                    value={
                      periodComment !== ""
                        ? new Date(constatationDate).toLocaleDateString() +
                          " : " +
                          periodComment
                        : actualityComment === ""
                        ? "Pas de commentaire disponible"
                        : actualityComment
                    }
                    style={{
                      marginLeft: 20,
                      margin: 20,
                      borderRadius: 10,
                      border: "none",
                      width: "85%",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <Box
                  className={classes.whiteContainer}
                  style={{ display: "block" }}
                >
                  {date_const_init === undefined ? (
                    <>
                      <ReactResizeDetector
                        handleWidth
                        onResize={x2 => setWidth2(x2)}
                      />
                      <ActionSecondChart
                        width={width2}
                        data={dataSecChart}
                        couponDates={couponDates}
                        indexNames={indexNames}
                        barrieresDispo={barrieresDispo}
                        barrieresRemb={barrieresRemb}
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        style={{ marginBottom: 30 }}
                        onClick={() => setHistory(!history)}
                      >
                        {"Historique"}
                      </Button>
                      <Button
                        style={{ marginBottom: 30 }}
                        onClick={() => setFutur(!futur)}
                      >
                        {"Futur"}
                      </Button>
                      <>
                        <ReactResizeDetector
                          handleWidth
                          onResize={x2 => setWidth2(x2)}
                        />
                        <ActionSecondChart
                          width={width2}
                          data={
                            history
                              ? dataSecChart
                              : dataSecChart.filter(
                                  e =>
                                    new Date(e.year) >=
                                    new Date(date_const_init)
                                )
                          }
                          indexNames={indexNames}
                          barrieresDispo={barrieresDispo}
                          barrieresRemb={barrieresRemb}
                          couponDates={couponDates}
                        />
                      </>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </>
        ) : (
          <Box />
        )}

        <Grid className={classes.ActionTab} item xs={12} sm={12} md={10}>
          {loadingBarrieres ? (
            <Box className={`${classes.tableContainer} ${classes.center}`}>
              <CircularProgress />
            </Box>
          ) : barrieres ? (
            <TableA7StocksDetails
              titles1={titles1}
              titles2={titles2}
              //name={store.index.map((line, i) => line.index_id[1])}
              rows={barrieres.sort(compareEndDate).map((line, index) => [
                <Box
                  key={index}
                  className={reportingMarker(line.start_date, line.end_date)}
                >
                  {line.indexation_id[1]}
                </Box>,
                <Box
                  className={reportingMarker(line.start_date, line.end_date)}
                >
                  {new Date(line.date_constatation_initiale).toLocaleDateString(
                    undefined,
                    options
                  )}
                </Box>,
                <Box
                  className={reportingMarker(line.start_date, line.end_date)}
                >
                  {line.valeur_evaluation.toFixed(2)}
                </Box>,
                <Box
                  className={reportingMarker(line.start_date, line.end_date)}
                >
                  {new Date(line.date_derniere_constatation).toLocaleDateString(
                    undefined,
                    options
                  )}
                </Box>,
                <Box
                  className={reportingMarker(line.start_date, line.end_date)}
                >
                  {line.valeur_derniere_evaluation.toFixed(2)}
                </Box>,
                <Box
                  className={reportingMarker(line.start_date, line.end_date)}
                >
                  {new Date(line.start_date).toLocaleDateString(
                    undefined,
                    options
                  )}
                </Box>,
                <Box
                  className={reportingMarker(line.start_date, line.end_date)}
                >
                  {new Date(line.end_date).toLocaleDateString(
                    undefined,
                    options
                  )}
                </Box>,
                <Box
                  className={reportingMarker(line.start_date, line.end_date)}
                >
                  {line.barriere_remboursement + "%"}
                </Box>,
                <Box
                  className={reportingMarker(line.start_date, line.end_date)}
                >
                  {line.valeur_barriere_remboursement.toFixed(2)}
                </Box>,
                <Box
                  className={reportingMarker(line.start_date, line.end_date)}
                >
                  {line.barriere_paiement + "%"}
                </Box>,
                <Box
                  className={reportingMarker(line.start_date, line.end_date)}
                >
                  {line.valeur_barriere_paiement.toFixed(2)}
                </Box>,
                <Box
                  className={reportingMarker(line.start_date, line.end_date)}
                >
                  {line.barriere_protection + "%"}
                </Box>,
                <Box
                  className={reportingMarker(line.start_date, line.end_date)}
                >
                  {line.valeur_barriere_protection.toFixed(2)}
                </Box>,
              ])}
            />
          ) : (
            <Box />
          )}
        </Grid>
      </Box>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  wrap: {
    display: "flex",
    padding: "60px 100px 30px 100px",
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
  },
  loading: {
    textAlign: "center",
  },
  AreaChartTextGrid: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  AreaChartText: {
    fontSize: 26,
    fontWeight: "bold",
    color: theme.palette.white.main,
    padding: "140px 0 0 60px",
    position: "absolute",
    maxWidth: 240,
    [theme.breakpoints.down("xs")]: {
      padding: 10,
      display: "none",
    },
  },
  ActionTab: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 80,
    marginBottom: 80,
  },
  whiteContainer: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    backgroundColor: theme.palette.white.main,
    boxShadow: `0px 3px 15px ${makeTransparent(
      theme.palette.primary.main,
      0.5
    )}`,
    padding: 60,
    borderRadius: 20,
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
  },
  blockGray: {
    width: "70%",
    height: "25%",
    backgroundColor: "#878889",
    borderRadius: "0px 0px 0px 40px",
    position: "absolute",
    marginTop: 115,
    zIndex: 0,
    right: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  btnWrapper: {
    textAlign: "center",
  },
  whiteBtn: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.primary.main,
    margin: "40px 40px 0 0",
    padding: 0,
    width: 100,
    height: 32,
    borderRadius: 10,
    [theme.breakpoints.down("sm")]: {
      margin: 10,
      width: 80,
    },
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white.main,
    },
  },
  center: {
    textAlign: "center",
  },
  reportingMarkerStyle: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
}));
