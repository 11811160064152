import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Input, Button, Box, Typography } from '@material-ui/core';
import Lil from '@loginline/core';
import { useHistory } from "react-router-dom";
import makeTransparent from '../utils/makeTransparent';
import { useInterlocutor } from '../hooks';

export default function MyAccount() {
    const classes = useStyles();
    const router = useHistory();

    const [interlocutor, loadingInterlocutor, errorInterlocutor] = useInterlocutor();

    // Récupération des données des inputs
    const [accountPassword, setAccountPassword] = useState('');
    const [accountNewPassword, setAccountNewPassword] = useState('');
    const [accountNewPasswordVerification, setAccountNewPasswordVerification] = useState('');

    // Statut du state lors de l'appel de la fonction
    const [wrongPass, setWrongPass] = useState(false);
    const [confirm, setConfirm] = useState(false);

    return (
        <Box className={classes.myAccount} style={{ backgroundImage: "url('../connexion-bg.png')", backgroundSize: "contain", backgroundRepeat: "no-repeat" }}>
            <Grid className={classes.content} container spacing={0}>
                <Grid item xs={11} sm={10} md={11} lg={6}>

                    <form onSubmit={(e) => {
                        e.preventDefault();
                        if (accountNewPassword === accountNewPasswordVerification) {
                            // eslint-disable-next-line
                            Lil.Account.changePassword(interlocutor.email, accountPassword, accountNewPassword, (result) => {
                                result === true ? setConfirm(true) : setWrongPass(true)
                            })
                                setTimeout(() => { router.push("/") }, 5000);
                        } else {
                            setWrongPass(true)
                        }
                    }}>
                        <Box className={classes.whiteContainer}>
                            <Typography variant="h2" className={classes.titleContainer}>
                                Changement du mot de passe
                        </Typography>
                            <Box className={classes.firstInput}>
                                <Typography variant="h4" className={classes.inputTitle}>
                                    Mot de passe actuel
                        </Typography>
                                <Input disableUnderline={true} placeholder="exemple@exemple.com" required={true} type="password"
                                    value={accountPassword} onChange={event => setAccountPassword(event.target.value)}
                                />
                            </Box>
                            <Box className={classes.secondInput}>
                                <Typography variant="h4" className={classes.inputTitle}>
                                    Nouveau mot de passe
                        </Typography>
                                <Input disableUnderline={true} placeholder="Votre nouveau mot de passe" required={true} type="password"
                                    value={accountNewPassword} onChange={event => setAccountNewPassword(event.target.value)}
                                />
                            </Box>
                            <Box className={classes.thirdInput}>
                                <Typography variant="h4" className={classes.inputTitle}>
                                    Confirmation nouveau mot de passe
                        </Typography>
                                <Input disableUnderline={true} placeholder="Confirmer votre nouveau mot de passe" required={true} type="password"
                                    value={accountNewPasswordVerification} onChange={event => setAccountNewPasswordVerification(event.target.value)}
                                />
                            </Box>
                            {wrongPass &&
                                <p style={{ color: '#FF0000' }}>Une erreur s'est produite, veuillez vérifier vos mots de passe</p>
                            }
                            {confirm &&
                                <p style={{ color: 'green' }}>Vos modifications ont bien été prises en compte, vous allez être redirigé vers la page de connexion</p>
                            }
                            <Box>
                                <Button className={classes.buttonConnect} type="submit">
                                    Valider
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </Grid>
            </Grid>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    myAccount: {
        flexGrow: 1,
        backgroundColor: theme.palette.light.main,
        minHeight: '100vh', // Centre automatiquement le container 
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    content: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    whiteContainer: {
        backgroundColor: theme.palette.white.main,
        borderRadius: 40,
        boxShadow: `0px 3px 15px ${makeTransparent(theme.palette.primary.main, 0.5)}`,
        alignItems: "center",
        textAlign: 'center',
    },
    titleContainer: {
        paddingTop: 30,
        paddingBottom: 40
    },
    firstInput: {
        marginBottom: 40
    },
    secondInput: {
        marginBottom: 40
    },
    thirdInput: {
        marginBottom: 20
    },
    inputTitle: {
        textAlign: "left",
        marginLeft: "10%",
        marginBottom: 11,
        marginTop: 20
    },
    buttonConnect: {
        marginTop: 25,
        marginBottom: 25
    },
}));