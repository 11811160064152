import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Button,
  Box,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import makeTransparent from "../utils/makeTransparent";
import useFillStore from "../hooks/useFillStore";
import { useInterlocutor } from "../hooks";

export default function Clients() {
  const classes = useStyles();
  const [{ clients }, loading, error] = useFillStore(
    "auth/readClients",
    {
      fields: ["id", "name"],
    },
    {
      fetchPolicy: "cache-first",
      cacheKey: "clients",
    },
    (store, result) => ({
      clients: {
        ...(store.clients || {}),
        ...result.reduce(
          (acc, client) => ({
            ...acc,
            [client.id]: client,
          }),
          {}
        ),
      },
    }),
    () => {
      console.error("Erreur lors de la récupération des clients");
    },
    []
  );
  const [interlocutor, loadingInterlocutor, errorInterlocutor] = useInterlocutor();
  const [{ logo }, loadingLogo, errorLogo] = useFillStore(
    "pub/logoUrl",
    {},
    {
      fetchPolicy: "cache-first",
      cacheKey: "logo",
    },
    (store, result) => ({
      logo: result.url,
    }),
    () => {
      console.error("Erreur lors de la récupération du logo");
    }
  );
  const router = useHistory();

  return (
    <Box
      className={classes.clients}
      style={{
        backgroundImage: "url('../connexion-bg.png')",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Grid className={classes.content} container spacing={0}>
        <Grid item xs={11} sm={10} md={11} lg={6}>
          <Box className={classes.logo}>
            {logo && <img src={logo} alt='logo' />}
          </Box>
          <Box className={classes.whiteContainer}>
            {interlocutor && (
              <Typography variant='h2' className={classes.titleContainer}>
                Bonjour {interlocutor.name},
              </Typography>
            )}
            {clients &&
              Object.values(clients).map(({ id, name }, index) => {
                return (
                  <Button
                    onClick={() => {
                      router.push(`/client/${id}`);
                    }}
                    key={index}
                    className={classes.buttonCompany}
                  >
                    {name}
                    <i className='fas fa-arrow-alt-circle-right'></i>
                  </Button>
                );
              })}
            {loading && (
              <>
                <Typography variant='h4'>
                  Nous récupérons vos informations ...
                </Typography>
                <br />
                <CircularProgress />
              </>
            )}
            {error && <text>{error}</text>}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  clients: {
    flexGrow: 1,
    backgroundColor: theme.palette.light.main,
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    textAlign: "center",
    marginTop: 30,
  },
  whiteContainer: {
    position: "relative",
    backgroundColor: theme.palette.white.main,
    padding: theme.spacing(2),
    marginTop: 30,
    marginBottom: 80,
    borderRadius: 40,
    boxShadow: `0px 3px 15px ${makeTransparent(
      theme.palette.primary.main,
      0.5
    )}`,
    alignItems: "center",
    textAlign: "center",
  },
  titleContainer: {
    marginTop: 52,
    marginBottom: 47,
    textAlign: "left",
    marginLeft: "8%",
  },
  buttonCompany: {
    width: "80%",
    height: 60,
    marginBottom: 32,
    justifyContent: "space-between",
    paddingLeft: "5%",
    paddingRight: "5%",
    fontSize: 20,
  },
  rotate: {
    WebkitAnimation: "spin 2s linear infinite;",
    MozAnimation: "spin 2s linear infinite;",
    animation: "spin 2s linear infinite;",
  },
  icon: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "15px",
    right: "60px",
  },
}));
