import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  Typography,
  CircularProgress,
  Snackbar,
  IconButton,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import Header from "../Components/Header";
import makeTransparent from "../utils/makeTransparent";
import { useDeleteNotification, useNotifications } from "../hooks";
import { formatDate } from "../utils";

export default function Notifications() {
  const classes = useStyles();
  let { clientId } = useParams();
  const [displayError, setDisplayError] = useState(null);

  const [notifications, loadingNotifications, errorNotifications] =
    useNotifications(clientId);

  const [removeNotif, deleting, errorDelete] = useDeleteNotification();

  useEffect(() => {
    if (errorNotifications) {
      setDisplayError(errorNotifications);
    }
    if (errorDelete) {
      setDisplayError(errorDelete);
    }
  }, [errorNotifications, errorDelete]);

  return (
    <>
      <Header title='Notifications' />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={displayError}
        autoHideDuration={6000}
        onClose={() => setDisplayError(null)}
        message={displayError || ""}
        action={
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={() => setDisplayError(null)}
          >
            <i className='fas fa-times'></i>
          </IconButton>
        }
      />

      <Grid className={classes.notifications} container spacing={0}>
        <Grid item xs={12} sm={8} md={6}>
          <>
            {loadingNotifications ? (
              <CircularProgress className={classes.loading} />
            ) : notifications && notifications.length > 0 ? (
              notifications.map(notif => {
                return (
                  <Box key={notif.id} className={classes.notificationContainer}>
                    <Grid item xs={3} md={3}>
                      <Typography
                        variant='h4'
                        className={classes.notificationDate}
                      >
                        {formatDate(notif.date)}
                      </Typography>
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <Typography
                        variant='body1'
                        className={classes.notificationText}
                      >
                        {notif.commentary}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} md={1} className={classes.colorIcon}>
                      <IconButton
                        disabled={deleting}
                        onClick={event => {
                          event.preventDefault();
                          event.stopPropagation();
                          removeNotif(notif.id);
                        }}
                      >
                        <i
                          className='fas fa-trash fa-lg'
                          style={{ cursor: "pointer" }}
                        ></i>
                      </IconButton>
                    </Grid>
                  </Box>
                );
              })
            ) : (
              <Box className={classes.tableContainer}>
                <Typography variant='h2'>
                  Aucune notification à afficher
                </Typography>
              </Box>
            )}
          </>
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  notifications: {
    backgroundColor: theme.palette.light.primary,
    display: "flex",
    justifyContent: "center",
    marginTop: 75,
    minHeight: "75vh",
  },
  notificationList: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "center",
  },
  notificationContainer: {
    background: `linear-gradient(to right, ${theme.palette.primary.main} 22%, white 11.5%)`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 40,
    paddingLeft: "3%",
    paddingRight: "3%",
    paddingTop: "3%",
    paddingBottom: "3%",
    marginBottom: 30,
    textAlign: "center",
  },
  notificationDate: {
    color: theme.palette.white.main,
    textAlign: "center",
    fontSize: "1em",
    paddingRight: "20%",
  },
  colorIcon: {
    color: theme.palette.primary.main,
    textAlign: "center",
  },
  loading: {
    padding: 100,
    marginLeft: 250,
  },
  tableContainer: {
    position: "relative",
    padding: theme.spacing(2),
    textAlign: "center",
    marginTop: 30,
    marginBottom: 80,
    backgroundColor: theme.palette.white.main,
    boxShadow: `0px 3px 15px ${makeTransparent(
      theme.palette.primary.main,
      0.5
    )}`,
    borderRadius: 40,
    alignItems: "center",
    paddingLeft: 80,
    paddingRight: 80,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
}));
