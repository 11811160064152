import React, { useState } from "react";
import {
  Grid,
  Button,
  Box,
  Typography,
  CircularProgress,
  Paper,
  Snackbar,
  Icon,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Lil from "@loginline/core";
import { useParams } from "react-router-dom";
import Header from "../Components/Header";
import TableA7Stocks from "../Components/TableA7Stocks";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import makeTransparent from "../utils/makeTransparent";
import {
  compareExpiryDate,
  formatDate,
  formatPrice,
} from "../utils";
import {
  useAssetClasses,
  useClients,
  useClientStocks,
  useComments,
  useIndexations,
  useInstruments,
} from "../hooks";
import { Link } from "react-router-dom";

// Encart Total
const titles_total = [
  "Montant :",
  "Montant valorisé en J :",
  "Montant garanti :",
  "+/- Value latente depuis la souscription :",
  "Coupons perçus depuis la souscription :",
  "Résultat net depuis la souscription :",
];

// Encart Détails
const titles = [
  "Instrument financier",
  "Référence",
  "Émetteur",
  "Date de début",
  "Montant",
  "+/- Value latente depuis la souscription",
  "Date d'échéance",
  "Rendement des cp",
  "Montants valorisés en J",
  "",
];

// Encart du déroulé d'un instrument financier
const titles_details = [
  "Nombre de parts : ",
  "Catégorie de produits : ",
  "Rendement net : ",
  "Indexation :",
  "Dernière valorisation :",
  "Coupons perçus depuis la souscription :",
  "Espérance de rendement :",
  "",
  "Date dernière valorisation :",
  "Résultat net depuis la souscription :",
  "Montant garanti :",
  "",
  "Commentaire :",
  "",
  "",
  "",
  "Actualité :",
  "",
  "",
];

const titles_details_bank = [
  "Rendement net : ",
  "Coupons perçus depuis la souscription :",
  "Espérance de rendement :",
  "Commentaire :",
  "Résultat net depuis la souscription :",
  "Montant garanti :",
  "Actualité :",
  "",
  "",
  "",
  "",
];

const dateOoptions = { year: "numeric", month: "numeric", day: "numeric" };

export default function Stocks() {
  const classes = useStyles();
  // eslint-disable-next-line
  const [displaySnackbar, setDisplaySnackbar] = useState(false);
  const [loadingPDF, setLoadingPdf] = useState(false);
  let { clientId, assetClassId } = useParams();

  const [clients, loadingClients, errorClients] = useClients();
  const client = clients && clients[clientId];

  const [comments, loadingComments, errorComments] = useComments();

  const [stocks, loadingStocks, errorStocks] = useClientStocks(clientId, assetClassId);

  const clientAssetStocks =
    stocks && stocks[clientId] && stocks[clientId][assetClassId];
  const dataStocks = clientAssetStocks ? Object.values(clientAssetStocks) : [];
  const [indexations, loadingIndexations, errorIndexations] = useIndexations(
    stocks && stocks[clientId] && stocks[clientId][assetClassId]
      ? Object.values(stocks[clientId][assetClassId]).flatMap(
          x => x.indexation_ids
        )
      : undefined
  );

  const [instruments, loadingInstruments, errorInstruments] = useInstruments(
    stocks && stocks[clientId] && stocks[clientId][assetClassId]
      ? Object.values(stocks[clientId][assetClassId]).flatMap(
          x => x.instrument_id[0]
        )
      : undefined
  );

  const [assetClasses, loadingAssetClasses, errorAssetClasses] =
    useAssetClasses();

  // Somme des montants
  const {
    amount,
    amountValuedInDays,
    guaranteedAmount,
    unrealizedValueSinceSubscription,
    couponsReceivedSinceSubscription,
    netResultSinceSubscription,
  } = clientAssetStocks
    ? Object.values(clientAssetStocks).reduce(
        (acc, cv) => ({
          amount: acc.amount + cv.montant_reel_encours_customer,
          amountValuedInDays:
            acc.amountValuedInDays + cv.montant_valorisation_encours_customer,
          guaranteedAmount:
            acc.guaranteedAmount + cv.montant_garanti_encours_customer,
          unrealizedValueSinceSubscription:
            acc.unrealizedValueSinceSubscription +
            cv.plus_value_souscription_encours_customer,
          couponsReceivedSinceSubscription:
            acc.couponsReceivedSinceSubscription +
            cv.coupon_percu_souscription_encours_customer,
          netResultSinceSubscription:
            acc.netResultSinceSubscription +
            cv.resultat_net_souscription_encours_customer,
        }),
        {
          amount: 0,
          amountValuedInDays: 0,
          guaranteedAmount: 0,
          unrealizedValueSinceSubscription: 0,
          couponsReceivedSinceSubscription: 0,
          netResultSinceSubscription: 0,
        }
      )
    : {
        amount: 0,
        amountValuedInDays: 0,
        guaranteedAmount: 0,
        unrealizedValueSinceSubscription: 0,
        couponsReceivedSinceSubscription: 0,
        netResultSinceSubscription: 0,
      };

  const rows_total = [
    formatPrice(amount),
    formatPrice(amountValuedInDays),
    formatPrice(guaranteedAmount),
    formatPrice(unrealizedValueSinceSubscription),
    formatPrice(couponsReceivedSinceSubscription),
    formatPrice(netResultSinceSubscription),
  ];

  return (
    <>
      <Snackbar
        open={displaySnackbar}
        onClose={() => setDisplaySnackbar(false)}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Paper style={{ padding: 10, backgroundColor: "red" }}>
          <Typography style={{ color: "white" }}>
            Pas de fichier PDF disponible
          </Typography>
        </Paper>
      </Snackbar>

      <Header title={(assetClasses && assetClasses[assetClassId].name) || ""} />

      <Typography variant='h4' className={classes.date}>
        Reporting au {client && formatDate(client.date_analyse_encours_customer)}
        {/* {formatDate(new Date())} */}
      </Typography>

      <Grid className={classes.actions} container spacing={0}>
        <Grid item xs={12} sm={10} md={10}>
          <Box className={classes.whiteContainer}>
            <Icon
              className={`fa fa-${
                loadingStocks ? `sync ${classes.rotate}` : "check-circle"
              } ${classes.icon}`}
            />
            <Typography variant='h2'>
              <Box className={classes.titleTotal}>Total</Box>
            </Typography>
            {clientAssetStocks ? (
              <Grid container spacing={3}>
                {titles_total.map((title, index) => {
                  const value = rows_total[index];
                  return (
                    <Grid key={index} item xs={6} sm={4}>
                      <Box className={classes.categories}>
                        <Typography
                          className={classes.subtitleDetails}
                          variant='h4'
                        >
                          {title}
                        </Typography>
                        <Typography variant='h3'>{value}</Typography>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Box className={classes.tableContainer}>
                <CircularProgress />
              </Box>
            )}

            <Typography variant='h2'>
              <Box className={classes.titleDetails}>Détail</Box>
            </Typography>

            {/* Spécifique à la classe d'actif "Ep Bancaire" */}
            {assetClasses && assetClasses[assetClassId].name === "Ep Bancaire" ? (
              !loadingStocks ? (
                <TableA7Stocks
                  titles={titles}
                  rows={dataStocks.sort(compareExpiryDate).map(line => {
                    const instrument =
                      (instruments && instruments[line.instrument_id[0]]) || {};
                    return [
                      // Reference
                      (instrument && instrument.name) || "",
                      (instrument && instrument.code_isin) || "",
                      (instrument && instrument.emetteur_id && instrument.emetteur_id[1]) || "",
                      new Date(line.date_debut).toLocaleDateString(
                        undefined,
                        dateOoptions
                      ),
                      formatPrice(
                        line.montant_reel_encours_customer.toFixed(2)
                      ),
                      formatPrice(
                        line.plus_value_souscription_encours_customer
                      ),
                      line.date_fin
                        ? new Date(line.date_fin).toLocaleDateString(
                            undefined,
                            dateOoptions
                          )
                        : "",
                      line.rendement_coupon_couru_encours_customer.toFixed(2) +
                        "%",
                      formatPrice(
                        line.montant_valorisation_encours_customer.toFixed(2)
                      ),
                    ];
                  })}
                  titles_details={titles_details_bank}
                  rows_details={dataStocks.map((line, i) => {
                    const instrument =
                      instruments && instruments[line.instrument_id[0]];
                    const comment =
                      instrument &&
                      comments &&
                      comments[instrument.comments.slice(-1)[0]];
                    return [
                      line.rendement_net_souscription_encours_customer.toFixed(
                        2
                      ) + "%",
                      line.categorie_produit[1],
                      line.rendement_estime,
                      <TextareaAutosize
                        aria-label='minimum height'
                        rowsMin={5}
                        value={(instrument && instrument.commentaire) || ""}
                        style={{
                          borderRadius: 10,
                          border: "none",
                          width: "35vh",
                        }}
                      />,
                      formatPrice(
                        line.resultat_net_souscription_encours_customer
                      ),
                      formatPrice(line.montant_garanti_encours_customer),
                      <TextareaAutosize
                        aria-label='minimum height'
                        rowsMin={4}
                        value={(comment && comment.text) || ""}
                        style={{
                          borderRadius: 10,
                          border: "none",
                          width: "30vh",
                        }}
                      />,
                      <Button
                        className={classes.buttonPdf}
                        onClick={() => {
                          setLoadingPdf(true);
                          Lil.method(
                            "auth/readPdfFinancialInstrument",
                            { instrument_id: line.instrument_id[0] },
                            result => {
                              Lil.DownloadPdf(
                                result[0],
                                "datas",
                                result[0].datas_fname
                              );
                              setLoadingPdf(false);
                            }
                          );
                        }}
                      >
                        {loadingPDF ? (
                          <CircularProgress />
                        ) : (
                          <>
                            Télécharger le PDF &nbsp;{" "}
                            <i className='fas fa-file-download' />{" "}
                          </>
                        )}
                      </Button>,
                    ];
                  })}
                />
              ) : (
                <Box className={classes.tableContainer}>
                  <CircularProgress />
                </Box>
              )
            ) : dataStocks && dataStocks.length > 0 ? (
              <TableA7Stocks
                titles={titles}
                rows={dataStocks.map(line => {
                  const instrument =
                    instruments && instruments[line.instrument_id[0]];
                  return [
                    // Reference
                    line.instrument_id[1],
                    (instrument && instrument.code_isin) || "",
                    (instrument &&
                      instrument.emetteur_id &&
                      instrument.emetteur_id[1]) ||
                      "",
                    new Date(line.date_debut).toLocaleDateString(
                      undefined,
                      dateOoptions
                    ),
                    formatPrice(line.montant_reel_encours_customer.toFixed(2)),
                    formatPrice(line.plus_value_souscription_encours_customer),
                    line.date_fin
                      ? new Date(line.date_fin).toLocaleDateString(
                          undefined,
                          dateOoptions
                        )
                      : "",
                    line.rendement_coupon_couru_encours_customer.toFixed(2) +
                      "%",
                    formatPrice(
                      line.montant_valorisation_encours_customer.toFixed(2)
                    ),
                  ];
                })}
                titles_details={titles_details}
                rows_details={dataStocks.map((line, i) => {
                  const instrument =
                    instruments && instruments[line.instrument_id[0]];
                  const comment =
                    instrument &&
                    comments &&
                    instrument.comments.length > 0 &&
                    comments[instrument.comments.slice(-1)[0]];
                  return [
                    line.nombre,
                    line.categorie_produit[1],
                    line.rendement_net_souscription_encours_customer.toFixed(
                      2
                    ) + "%",
                    indexations ? (
                      line.indexation_ids
                        .map(x => (indexations[x] && indexations[x].name) || "")
                        .join(" - ")
                    ) : (
                      <CircularProgress />
                    ),
                    formatPrice(line.derniere_valorisation_encours_customer),
                    formatPrice(
                      line.coupon_percu_souscription_encours_customer
                    ),
                    line.rendement_estime,
                    <Button
                      className={classes.buttonPdf}
                      onClick={() => {
                        setLoadingPdf(true);
                        Lil.method(
                          "auth/readPdfFinancialInstrument",
                          { instrument_id: line.instrument_id[0] },
                          result => {
                            Lil.DownloadPdf(
                              result[0],
                              "datas",
                              result[0].datas_fname
                            );
                            setLoadingPdf(false);
                          }
                        );
                      }}
                    >
                      {loadingPDF ? (
                        <CircularProgress />
                      ) : (
                        <>
                          Télécharger le PDF &nbsp;{" "}
                          <i className='fas fa-file-download' />{" "}
                        </>
                      )}
                    </Button>,
                    new Date(
                      line.date_derniere_valorisation
                    ).toLocaleDateString(undefined, dateOoptions),
                    formatPrice(
                      line.resultat_net_souscription_encours_customer
                    ),
                    formatPrice(line.montant_garanti_encours_customer),
                    <Link to={`/client/${clientId}/${assetClassId}/${line.instrument_id[0]}`}>
                      <Button className={classes.buttonGraphic}>
                        Graphique de valorisation
                      </Button>
                    </Link>,
                    <TextareaAutosize
                      aria-label='minimum height'
                      rowsMin={4}
                      readOnly
                      value={(instrument && instrument.commentaire) || ""}
                      style={{
                        borderRadius: 10,
                        border: "none",
                        width: "90vh",
                      }}
                    />,
                    "",
                    "",
                    "",
                    <TextareaAutosize
                      aria-label='minimum height'
                      rowsMin={4}
                      readOnly
                      value={(comment && comments.text) || ""}
                      style={{
                        borderRadius: 10,
                        border: "none",
                        width: "90vh",
                      }}
                    />,
                  ];
                })}
              />
            ) : (
              <Box className={classes.tableContainer}>
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles(theme => {
  return {
    actions: {
      backgroundColor: theme.palette.light.main,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    date: {
      textAlign: "center",
    },
    whiteContainer: {
      position: "relative",
      padding: theme.spacing(10),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2),
      },
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(6),
      },
      marginTop: 74,
      marginBottom: 80,
      backgroundColor: theme.palette.white.main,
      boxShadow: `0px 3px 15px ${makeTransparent(
        theme.palette.primary.main,
        0.5
      )}`,
      borderRadius: 40,
    },
    titleTotal: {
      textAlign: "left",
      marginBottom: 35,
    },
    categories: {
      maxWidth: 215,
    },
    titleDetails: {
      textAlign: "left",
      marginBottom: 35,
      marginTop: 65,
    },
    buttonDetails: {
      width: 100,
      height: 28,
      fontSize: 14,
      padding: 0,
      borderRadius: 10,
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
    },
    buttonReduce: {
      width: 100,
      height: 28,
      fontSize: 14,
      padding: 0,
      marginBottom: 10,
      borderRadius: 10,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white.main,
    },
    subtitleDetails: {
      fontWeight: "bold",
      color: theme.palette.primary.main,
    },
    buttonPdf: {
      width: 272,
      height: 50,
      backgroundColor: theme.palette.white.main,
      borderColor: theme.palette.white.main,
      color: theme.palette.primary.main,
    },
    buttonGraphic: {
      width: 272,
      height: 50,
    },
    rotate: {
      WebkitAnimation: "spin 2s linear infinite;",
      MozAnimation: "spin 2s linear infinite;",
      animation: "spin 2s linear infinite;",
    },
    icon: {
      color: theme.palette.primary.main,
      position: "absolute",
      top: "15px",
      right: "60px",
    },
  };
});
