import React, { useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { hot } from "react-hot-loader";
import Lil from "@loginline/core";

// Components
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";

//Pages
import Connexion from "./Pages/Connexion";
import Clients from "./Pages/Clients";
import Home from "./Pages/Home";
import Stocks from "./Pages/Stocks";
import StocksDetails from "./Pages/StocksDetails";
import Notifications from "./Pages/Notifications";
import Events from "./Pages/Events";
import Synthesis from "./Pages/Synthesis";
import MyAccount from "./Pages/MyAccount";
import NotFound from "./Pages/NotFound";

function App() {
  const [store, update] = Lil.useStore();

  useEffect(() => {
    const fetch = async () => {
      try {
        Lil.method(
          "auth/readInterlocutor",
          {
            fields: ["id"],
          },
          () => update({ userLoggedIn: true })
        );
      } catch (err) {
        update({ userLoggedIn: false });
      }
    };
    fetch();
  }, []);

  return (
    <div className='App'>
      <Route path='/client/:clientId' render={() => <Navbar />} />
      <Switch>
        <Route exact path='/' component={Connexion} />
        <Route exact path='/clients' component={Clients} />
        <Route exact path='/client/:clientId' component={Home} />
        <Route
          exact
          path='/client/:clientId/notifications'
          component={Notifications}
        />
        <Route exact path='/client/:clientId/events' component={Events} />
        <Route exact path='/client/:clientId/synthesis' component={Synthesis} />
        <Route
          exact
          path='/client/:clientId/:assetClassId'
          component={Stocks}
        />
        <Route
          exact
          path='/client/:clientId/:assetClassId/:instrumentId'
          component={StocksDetails}
        />
        <Route exact path='/app/myaccount' component={MyAccount} />
        <Route exact path='/' component={NotFound} />
      </Switch>
      <Route path='/:page' component={Footer} />
    </div>
  );
}

export default hot(module)(App);
